import axios, { InternalAxiosRequestConfig } from 'axios'
import store, { TOKEN_KEY } from './store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
})

const interceptors = {
  request: (config: InternalAxiosRequestConfig) => {
    config['headers'] = config.headers ?? {}
    const token = localStorage.getItem(TOKEN_KEY) ?? null
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  responseError: (error: any) => {
    if (error.response.status === 400) {
      store.commit('error', error.response.data.detail)
    }

    if (error.response.status === 401) {
      store.commit('token', null)
    }
    return Promise.reject(error)
  },
}

api.interceptors.request.use(interceptors.request)
api.interceptors.response.use(
  response => response,
  error => interceptors.responseError(error),
)

export default api
